<template>
  <center-content>
    <form @submit.prevent="changePassword">
      <base-card footer="bg-e0">
        <template #header>تغيير كلمة المرور</template>
        <template #body>
          <div class="form-group text-left">
            <label>كلمة المرور</label>
            <FormInput
              id="current_password"
              type="password"
              placeholder="كلمة المرور الحالية"
              autofocus
            />
          </div>

          <div class="form-group text-left">
            <label>كلمة المرور</label>
            <FormInput
              id="password"
              type="password"
              placeholder="كلمة المرور"
            />
          </div>

          <div class="form-group text-left mb-0">
            <label>تأكيد كلمة المرور</label>
            <FormInput
              id="password_confirmation"
              type="password"
              placeholder="تأكيد كلمة المرور"
            />
          </div>
        </template>
        <template #footer>
          <button type="submit" class="btn btn-dark nr-block mb-1">حفظ</button>
        </template>
      </base-card>
    </form>
  </center-content>
</template>

<script>
import BaseCard from "../../UI/BaseCard";
import FormInput from "../../Forms/FormInput";
import CenterContent from "@/components/UI/CenterContent";

export default {
  components: { CenterContent, FormInput, BaseCard },
  computed: {
    authorized() {
      return this.$store.getters.authorized;
    },
  },
  methods: {
    async changePassword(e) {
      if (!this.authorized) {
        return;
      }

      if (
        !$_("#current_password").val() ||
        !$_("#password").val() ||
        !$_("#password_confirmation").val()
      ) {
        return http.popupMessage("error", messages.checkFields);
      }

      const formData = new FormData(e.target);
      formData.set("user", this.authorized.id);

      const response = await http.send("change-password", formData);

      if (http.responseAccepted(response, "token")) {
        await this.setToken(response.data.token);
        await this.$router.replace("/");
        http.popupMessage("success", messages.passwordChanged);
      }
    },
    async setToken(token) {
      const authorized = this.authorized;
      authorized["token"] = token;
      await this.$store.dispatch("setAuthorized", authorized);
    },
  },
};
</script>
